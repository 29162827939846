<template>
  <div>
    <h2>Parts</h2>
    <hr />
    <div class="mb-3" v-if="store || admin || igta">
      <b-button variant="danger" to="/parts/create">Add Part</b-button>
      <span>&ensp;</span>
      <b-button variant="secondary" to="/categories"
        >Manage Categories</b-button
      >
      <span>&ensp;</span>
      <b-button variant="danger" to="/vendors">Manage Vendors</b-button>
      <span>&ensp;</span>
      <b-button variant="secondary" to="/parts/lowparts"
        >See Low Parts</b-button
      >
      <span>&ensp;</span>
      <vue-excel-xlsx
        class="btn btn-danger"
        :data="parts"
        :columns="columns"
        :file-name="'Inventory_Report(' + getDateTime() + ')'"
        :file-type="'xlsx'"
        :sheet-name="'sheetname'"
      >
        <i class="fa fa-download" /> Inventory Report
      </vue-excel-xlsx>
    </div>
    <hr />
    <PartSearch
      :categories="shownCategories"
      :parts="parts"
      v-on:update="update"
    ></PartSearch>
    <b-alert variant="danger" v-model="showFailure" dismissible>
      Error getting parts!
    </b-alert>
    <div v-if="!isLoading" class="loader"></div>
    <PartList v-else :parts="shownParts" v-on:update="refresh"></PartList>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import api from '../../api/parts';
import categories from '../../api/categories';

import PartList from '../../components/parts/PartList.vue';
import PartSearch from '../../components/parts/PartSearch.vue';
import roles from '../../shared/roles';

@Component({
  components: {
    PartList,
    PartSearch,
  },
})
export default class PartIndex extends Vue {
  showFailure = false;
  isLoading = false;

  parts: Part[] = [];
  shownParts: Part[] = [];

  categories: { text: string; value: string }[] = [];
  shownCategories: { text: string; value: string }[] = [];

  columns = [
    {
      label: 'Part #',
      field: 'partKey',
    },
    {
      label: 'Part Name',
      field: 'name',
    },
    {
      label: 'Part Category',
      field: 'categoryName',
    },
    {
      label: 'Part Vendor',
      field: 'vendorName',
    },
    {
      label: 'Quantity',
      field: 'quantity',
    },
    {
      label: 'Price',
      field: 'price',
      dataFormat: (value: number) => {
        return Number(value).toLocaleString('us-US', {
          style: 'currency',
          currency: 'USD',
        });
      },
    },
  ];

  getDateTime() {
    const date = new Date();
    return (
      date.getFullYear() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2) +
      ' ' +
      ('0' + date.getHours()).slice(-2) +
      '_' +
      ('0' + date.getMinutes()).slice(-2) +
      '_' +
      ('0' + date.getSeconds()).slice(-2)
    );
  }

  get admin() {
    return roles.hasRole(roles.RoleType.Admin);
  }

  get igta() {
    return roles.hasRole(roles.RoleType.InstructorGTA);
  }

  get store() {
    return roles.hasRole(roles.RoleType.Store);
  }

  update(shownParts: Part[]) {
    this.updateParts(shownParts);
    this.updateCategories();
  }

  updateParts(shownParts: Part[]) {
    this.shownParts = shownParts;
  }

  updateCategories() {
    const availableCategories = new Set<string>(
      this.parts.map((part) => part.categoryName),
    );
    this.shownCategories = [
      { text: 'All Categories', value: 'All Categories' },
    ];
    this.categories.forEach((elem) => {
      if (availableCategories.has(elem.text)) {
        this.shownCategories.push({ text: elem.text, value: elem.value });
      }
    });
  }

  refresh() {
    this.isLoading = false;
    api.getParts().then((res) => {
      if (!res.success) {
        if (res.status === 402) {
          this.$router.push('/overdue');
        } else {
          this.showFailure = true;
        }
      } else {
        this.parts = res.data;
        const indices = Array.from(this.parts.keys());

        // Initial sorting of parts
        indices.sort((a, b) =>
          this.parts[a]?.name.localeCompare(this.parts[b]?.name),
        );
        this.parts = indices.map((i) => this.parts[i]);
        this.updateParts(this.parts);
        this.isLoading = true;
      }
    });

    categories.getCategories().then((res) => {
      if (res.status) {
        res.data?.forEach((elem: Category) => {
          this.categories.push({ text: elem.name, value: elem.name });
        });
        this.updateCategories();
      }
    });
  }

  mounted() {
    this.refresh();
  }
}
</script>

<style>
.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #ba0c2f;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
